<script lang="ts">
  import { xhr } from "@/utilities/xhr"
  import { formatDec, toastMsg } from "@/utilities/format"
  import { loadingAction } from "svelte-legos"
  import Password from "@/svelte/components/Password.svelte"
  import { onMount } from "svelte"
  import type { CourseType, StripePriceType } from "@/types"
  import { loaderCircle } from "@/icons"
  import Description from "./description.svelte"

  export let assessment_code: string = ""
  export let stripe_publish_key: string = ""
  export let price: StripePriceType
  export let coupon = ""
  export let courses: CourseType[] = []
  console.log({ price, coupon, courses, stripe_publish_key })
  let course = courses[0]

  let form = {
    name: "",
    email: "",
    password: "",
    assessment_code,
    stripe_token: "",
    stripe_card_id: "",
    coupon: coupon,
    course_code: "",
  }

  if (course) form.course_code = course.code

  let errors: { [key: string]: any } = {}
  let saving = false
  async function registerUser(): Promise<void> {
    saving = true
    errors = {}
    // @ts-ignore
    window.cardNode = cardNode
    // @ts-ignore
    window.stripe = stripe
    try {
      const result = await stripe.createToken(cardElement)
      if (result.error) {
        toastMsg(`Invalid card details\n${result.error.message}`, "error")

        return
      }

      form.stripe_token = result.token.id
      form.stripe_card_id = result.token.card.id

      const resp = await xhr.post("/individual_player_registration.json", {
        registration: { ...form },
      })

      if ([200, 201].includes(resp.status)) {
        toastMsg("Registration successful", "success")
        window.location.href = "/individual_player_info"
      }
    } catch (e: any) {
      toastMsg(
        "There are errors in your registration.<br/> Please fix the errors.",
        "error",
      )

      console.error(e)
      console.log("ERROR")

      if (e.response?.data?.errors) {
        Object.keys(e.response.data.errors).forEach((key) => {
          errors[key] = e.response.data.errors[key]
        })
      }
    } finally {
      saving = false
    }
  }

  // Stripe
  let stripe: any
  let cardNode: HTMLElement
  let cardCSS = ""
  let cardElement: any

  let stripeLoaded = false
  async function loadStripe(): Promise<void> {
    // @ts-ignore
    stripe = Stripe(stripe_publish_key)
    let elements = stripe.elements()

    let style = {
      base: {
        fontSize: window.screen.availWidth > 600 ? "16px" : "14px",
        color: "#32325d",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#a0aec0",
        },
      },
    }
    cardElement = elements.create("card", { style: style })
    cardElement.mount(cardNode)

    // Focus, Blur
    cardElement.on("focus", () => (cardCSS = "ring-2 ring-blue-600"))
    cardElement.on("blur", () => (cardCSS = ""))

    stripeLoaded = true
  }

  let validCoupon = false
  let checkingCoupon = false
  let couponStatus = ""
  let discountPercent = 0
  let couponTime = 0
  async function checkCoupon(): Promise<void> {
    const currentTime = new Date().getTime()
    if (currentTime - couponTime < 250) return
    couponTime = currentTime

    if (form.coupon.trim() === "") {
      errors.coupon = ""
      couponStatus = ""
      return
    }

    checkingCoupon = true
    errors.coupon = ""

    try {
      const resp = await xhr.post("/valid_coupon", {
        coupon: form.coupon,
      })

      if (!resp.data.valid) throw "Invalid"

      if (resp.data.percent_off === 100) {
        couponStatus = "free"
      } else {
        couponStatus = "discount"
      }
      discountPercent = resp.data.percent_off
      validCoupon = true
    } catch (e) {
      couponStatus = "invalid"
      errors.coupon = "Invalid coupon"
      couponStatus = ""
      validCoupon = false
    } finally {
      checkingCoupon = false
    }
  }

  function selectCourse(course_id: number): void {
    let selCourse = courses.find((c) => c.id === course_id)
    course = selCourse
    form.course_code = course.code
  }

  onMount(() => {
    const interval = setInterval(() => {
      if (!stripeLoaded && Stripe) {
        loadStripe()
        clearInterval(interval)
      }
    }, 300)

    if (form.coupon !== "") checkCoupon()

    loadStripe()
  })
</script>

<div class="lg:grid lg:grid-cols-2 lg:gap-10">
  <div class="bg-white py-8 px-2 lg:px-8 rounded-2xl row-span-2 lg:order-last">
    <div class="space-y-4 md:px-10">
      <h1 class="text-3xl lg:text-4xl font-mono uppercase">
        Individual Assessment Registration
      </h1>

      <div class="input">
        <label for="name">Full Name *</label>
        <input
          type="text"
          name="name"
          bind:value={form.name}
          id="name"
          required
          class="w-full"
        />
        <div class="error-message">{errors.name || ""}</div>
      </div>

      <div class="input">
        <label for="email">Email *</label>
        <input
          type="email"
          name="email"
          bind:value={form.email}
          id="email"
          required
          class="w-full"
        />
        <div class="error-message">{errors.email || ""}</div>
      </div>

      <div class="input">
        <label for="password">Password *</label>
        <Password bind:value={form.password} class="w-full" />
        <div class="error-message">{errors.password || ""}</div>
      </div>
      <div class="input">
        <label for="signup_code">Assessment code</label>
        <input
          type="text"
          name="signup_code"
          bind:value={form.assessment_code}
          id="signup_code"
          class="w-full"
        />
      </div>

      {#if courses.length > 0}
        {@const seats_available = course.seats - course.seats_taken}
        <div class="bg-slate-50 p-2 md:p-4 rounded-md">
          <label for="" class="font-semibold">Class</label>
          <div>
            {#if courses.length > 0}
              <select
                on:change={(e) => selectCourse(+e.target.value)}
                class="text-sm mt-2 mb-4"
              >
                {#each courses as course}
                  <option value={course.id}>{course.name}</option>
                {/each}
              </select>
            {/if}
          </div>

          <div>
            <div class="font-semibold">{course.name}</div>
          </div>
          {#if course.seats_warning >= seats_available && seats_available > 0}
            <div class="text-yellow-700">
              Less than
              <span class="font-bold">
                {course.seats_warning}
              </span>
              seats available
            </div>
          {:else if seats_available <= 0}
            <div class="text-red-500 font-semibold">No seats available</div>
          {:else}
            <div>
              <span class="text-gray-500 font-semibold">Availability</span>
              <span class="text-green-500 font-bold">YES</span>
            </div>
          {/if}
        </div>
      {/if}

      <div class="text-sm">
        * <span class="text-gray-500">Required fields</span>
      </div>

      <hr />

      <h2 class="mb-4 text-3xl lg:text-4xl font-mono uppercase mt-16">
        Payment Details
      </h2>

      <div
        class="border rounded-full p-3 {cardCSS}"
        bind:this={cardNode}
        class:hidden={couponStatus === "free"}
      />

      <div class="relative" class:field_with_errors={errors.coupon}>
        <div>
          <div class="absolute top-5 left-2" class:hidden={!checkingCoupon}>
            {@html loaderCircle.replace("cssclass", "text-gray-400")}
          </div>
          <input
            class="w-full mt-2 border"
            class:input-success={validCoupon}
            placeholder="Coupon Code"
            type="text"
            bind:value={form.coupon}
            on:blur={() => {
              checkCoupon()
            }}
          />
          <button
            class="absolute whitespace-nowrap top-2 right-0 px-4 py-2 btn primary btn-sm"
            style="padding-top:10px;padding-bottom:10px"
            disabled={form.coupon === ""}
            on:click|stopPropagation={() => {
              checkCoupon()
            }}
          >
            Validate Coupon
          </button>
        </div>
        <div class="error-message">{errors.coupon || ""}</div>
      </div>

      {#if ["discount", "free"].includes(couponStatus)}
        {@const total =
          price.unit_amount - (price.unit_amount * discountPercent) / 100}
        <div class="flex gap-1">
          <div class="w-32">Price:</div>
          <div class="font-bold w-12 text-right">
            ${formatDec(price.unit_amount / 100)}
          </div>
        </div>
        <div class="flex gap-1">
          <div class="w-32">Discount:</div>
          <div class="font-bold w-12 text-right">
            -${formatDec((price.unit_amount * discountPercent) / 100 / 100)}
          </div>
        </div>
        <hr />
        <div class="text-lg flex gap-1">
          <div class="w-32">Total amount:</div>
          <div class="font-bold w-12 text-right">${formatDec(total / 100)}</div>
        </div>
      {:else}
        <div class="text-lg">
          Total amount:
          <span class="font-bold">${formatDec(price.unit_amount / 100)}</span>
        </div>
      {/if}

      <button
        on:click={registerUser}
        use:loadingAction={saving}
        class="py-2 px-8 text-center w-full mt-4 text-xl btn primary"
      >
        Register
      </button>
    </div>
  </div>

  <Description description={course?.description || ""} />
  <!-- </Layout> -->
</div>
